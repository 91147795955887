import React from "react";
import classNames from "classnames";
import ErrorsControl from "components/reusable/CompareReview/ErrorsControl";
import QualityControl from "components/reusable/CompareReview/QualityControl";
import DocumentImageViewer from "components/reusable/DocumentVerificationReview/DocumentImageViewer";

export const getImageLabel = (imageUrl) => {
  if (imageUrl.includes("Preview")) return "Selfie";
  if (imageUrl.includes("ID_Photo")) return "ID Authority Photo";
  if (imageUrl.includes("IDCard_Back")) return "Back of ID Card";
  if (imageUrl.includes("IDCard")) return "Front of ID Card";

  return "Liveness Check Photo";
};

const getImageType = (imageURL) => {
  if (imageURL.includes("Preview")) return "selfie";
  if (imageURL.includes("ID_Photo")) return "id_authority_photo";
  if (imageURL.includes("IDCard_Back")) return "id_card_back";
  if (imageURL.includes("IDCard")) return "id_card";

  return "liveness";
};

function CompareReviewImage({
  isControlGood,
  setIsControlGood,
  errors,
  setErrors,
  qualityName,
  errorsName,
  completed,
  imageUrls = undefined,
  className = undefined,
  images = undefined,
}) {
  const divClassName = classNames({ [className]: className });

  const displayImages = images
    ? images.map((image) => ({
        id: image.id,
        label: image.imageType.label,
        url: image.url,
        imageDetails: image,
        imageType: image.imageType.valueText,
      }))
    : imageUrls.map((url, idx) => ({
        url,
        id: `${url}-${idx}`,
        label: getImageLabel(url),
        imageType: getImageType(url),
      }));

  return (
    <div className={divClassName}>
      <div className="comparison-review-image">
        {displayImages.map((img) => (
          <div data-testid="review-image" key={img.id}>
            <div className="document-image">
              <DocumentImageViewer
                label={img.label}
                imageType={img.imageType}
                imageUrls={[img.url]}
              />
            </div>
          </div>
        ))}
      </div>
      <div>
        {!completed && (
          <QualityControl
            name={qualityName}
            isControlGood={isControlGood}
            setControl={setIsControlGood}
          />
        )}
        {isControlGood === false && (
          <ErrorsControl
            name={errorsName}
            errors={errors}
            setSource={setErrors}
          />
        )}
      </div>
    </div>
  );
}

export default CompareReviewImage;
